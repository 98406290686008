import Image from "next/image";
import React, { useRef, useState } from "react";
import { A11y, Autoplay, Controller, EffectFade, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import NodeBannerTeaser from "components/Banner/NodeBannerTeaser";
import classNames from "lib/classNames";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";
import { Parallax } from "react-scroll-parallax";
import useIsInViewport from "hook/useIsInViewport";
import { Transition } from "@headlessui/react";
import getDelayByIndex from "lib/getDelayByIndex";
import { useRouter } from "next/router";

export default function NodeBannerList({ nodes }) {
  const router = useRouter();
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const [bannerSwiper, setBannerSwiper] = useState(null);
  const [navSwiper, setNavSwiper] = useState(null);

  const redirectToAnchor = (anchor) => {
    const element = document.getElementById(anchor);
    const path = router.asPath.split("#")[0];

    if (element) {
      router.push(`${path}#${anchor}`);
    }
  };

  return (
    <section ref={ref} className="relative h-full">
      <Swiper
        modules={[A11y, Controller, EffectFade, Navigation]}
        slidesPerView={1}
        onSwiper={setBannerSwiper}
        controller={{ control: navSwiper }}
        effect="fade"
        className="h-full"
      >
        {nodes.map((node) => {
          return (
            <SwiperSlide
              key={node.id}
              className="h-auto pt-[104px] pb-[60%] lg:pt-24 xl:pt-32 lg:pb-0 2xl:pt-[104px] 2xl:pb-0"
            >
              <Parallax translateY={[-15, 15]} className="absolute inset-0">
                <Image
                  src={node.field_image.image_style_uri.hq}
                  {...node.field_image.resourceIdObjMeta}
                  layout="fill"
                  objectFit="cover"
                  objectPosition="center"
                />
                <div
                  className="block md:hidden absolute inset-0 bg-secondary/30 z-10"
                  aria-hidden="true"
                />
                <div
                  className="hidden md:block absolute inset-0 bg-gradient-to-t from-secondary via-transparent z-10"
                  aria-hidden="true"
                />
                <div
                  className="hidden md:block absolute inset-0 bg-gradient-to-r from-secondary z-10"
                  aria-hidden="true"
                />
              </Parallax>
              <div className="relative h-full pt-32 lg:pt-0 2xl:pt-32 pb-8 z-20">
                <NodeBannerTeaser key={node.id} node={node} />
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <div className="absolute bottom-8 inset-x-0">
        {navSwiper && (
          <div className="hidden sm:flex absolute bottom-0 right-[70%] lg:right-[65%] xl:right-[60%] gap-4 items-end justify-end z-10">
            <button
              type="button"
              className="p-4 rounded-full bg-white text-secondary hover:bg-primary transition-colors duration-300"
              onClick={() => navSwiper.slidePrev()}
            >
              <ChevronLeftIcon className="h-3 w-3" aria-hidden="true" />
              <div className="sr-only">Précédent</div>
            </button>
            <button
              type="button"
              className="p-4 rounded-full bg-white text-secondary hover:bg-primary transition-colors duration-300"
              onClick={() => navSwiper.slideNext()}
            >
              <ChevronRightIcon className="h-3 w-3" aria-hidden="true" />
              <div className="sr-only">Suivant</div>
            </button>
          </div>
        )}
        <div className="w-full">
          <Swiper
            modules={[A11y, Autoplay, Controller, Navigation]}
            spaceBetween={5}
            slidesPerView="auto"
            centeredSlides
            breakpoints={{
              1024: {
                slidesPerView: 5,
              },
              1280: {
                slidesPerView: 7,
              },
            }}
            autoplay={{ delay: 5000 }}
            onSwiper={setNavSwiper}
            controller={{ control: bannerSwiper }}
            a11y={{
              prevSlideMessage: "Section suivante",
              nextSlideMessage: "Section précédente",
            }}
            className="overflow-visible h-full clip-path-header lg:clip-path-header-lg xl:clip-path-header-xl"
            rewind
          >
            {nodes.map((node, index) => {
              return (
                <SwiperSlide key={node.id} className="max-w-[33.3%]">
                  {({ isActive }) => (
                    <Transition
                      show={isInViewport}
                      enter={classNames(
                        "transition-all duration-300",
                        getDelayByIndex(index)
                      )}
                      enterFrom="opacity-0 translate-y-10"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition-all duration-300"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-10"
                    >
                      <div
                        className={classNames(
                          "group relative rounded-md overflow-hidden leading-none transition-all border-b-4 cursor-pointer",
                          isActive
                            ? "border-primary"
                            : "border-black scale-90 translate-y-[5%]"
                        )}
                        onClick={() => redirectToAnchor(node.field_anchor)}
                      >
                        <div className="aspect-w-10 aspect-h-13">
                          <Image
                            src={node.field_image.image_style_uri.nav}
                            {...node.field_image.resourceIdObjMeta}
                            layout="fill"
                            objectFit="cover"
                            objectPosition="center"
                          />
                        </div>
                        <div
                          className="absolute inset-0 bg-gradient-to-t from-secondary-dark via-transparent"
                          aria-hidden="true"
                        />
                        <div className="absolute bottom-0 inset-x-0 p-4 font-extrabold text-sm">
                          {node.title}
                        </div>
                      </div>
                    </Transition>
                  )}
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
}
