import React from "react";
import Link from "next/link";

export default function FooterMenu({ menu }) {
  return (
    menu && (
      <ul className="flex flex-col lg:flex-row items-center justify-center gap-4 text-sm">
        {menu.map((item) => {
          return (
            <li key={item.id}>
              <Link href={item.url} passHref>
                <a className="text-white hover:text-primary transition-colors">
                  {item.title}
                </a>
              </Link>
            </li>
          );
        })}
      </ul>
    )
  );
}
