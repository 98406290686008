import React from "react";
import Link from "next/link";
import BrandingContent from "components/Branding/BrandingContent";

export default function Branding({ home = false, size = "default" }) {
  return home ? (
    <BrandingContent size={size} home />
  ) : (
    <Link href="/" passHref>
      <a className="inline-block no-underline hover:text-primary transition-colors duration-300">
        <BrandingContent size={size} />
      </a>
    </Link>
  );
}
