import { MenuIcon, XIcon } from "@heroicons/react/outline";
import useTranslation from "next-translate/useTranslation";
import React, { Fragment, useEffect, useState } from "react";
import MainMenu from "components/Menu/MainMenu";
import { Dialog, Transition } from "@headlessui/react";
import classNames from "lib/classNames";
import Branding from "components/Branding";

export default function Navbar({ globals }) {
  const { t } = useTranslation("common");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      document.body.classList.remove("overflow-y-visible");
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
      document.body.classList.add("overflow-y-visible");
    }
  }, [isOpen]);

  return (
    <nav className="flex h-full">
      <button
        type="button"
        className={classNames(
          "group flex items-center py-10 px-16 gap-5 bg-secondary hover:text-primary transition-colors duration-300",
          isOpen && "bg-secondary-light"
        )}
        onClick={() => setIsOpen(true)}
      >
        <span>Menu</span>
        <MenuIcon className="h-5 w-5 text-primary" aria-hidden="true" />
      </button>

      <Transition as={Fragment} show={isOpen}>
        <Dialog
          as="div"
          className="fixed inset-0 z-50"
          onClose={() => setIsOpen(false)}
        >
          <Transition.Child
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-default/70 transition-opacity" />
          </Transition.Child>

          <Transition.Child
            className="absolute inset-0 pt-28 xl:pt-44 pb-28 bg-secondary text-white h-full overflow-auto"
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="-translate-y-full"
            enterTo="translate-y-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="translate-y-0"
            leaveTo="-translate-y-full"
          >
            <div
              className="absolute inset-0 bg-pattern bg-repeat opacity-10 bg-[length:4px]"
              aria-hidden="true"
            />

            <div className="absolute top-0 left-0" aria-hidden="true">
              <Branding />
            </div>

            <div className="container px-4 mx-auto">
              {globals?.menus?.main && (
                <MainMenu
                  menu={globals.menus.main}
                  closeCallback={() => setIsOpen(false)}
                />
              )}
            </div>

            <button
              type="button"
              className="group absolute top-4 xl:top-10 right-4 xl:right-16 flex flex-col items-center justify-center gap-2 p-4 bg-primary rounded-full text-primary-dark"
              onClick={() => setIsOpen(false)}
            >
              <XIcon className="h-5 w-5" aria-hidden="true" />
              <span className="sr-only">{t("utils.close")}</span>
            </button>
          </Transition.Child>
        </Dialog>
      </Transition>
    </nav>
  );
}
