import React, { useRef } from "react";
import Button from "components/Button";
import useIsInViewport from "hook/useIsInViewport";
import Image from "next/image";
import { useRouter } from "next/router";
import Body from "components/Body";
import useTranslation from "next-translate/useTranslation";
import { Parallax } from "react-scroll-parallax";
import { Transition } from "@headlessui/react";

export default function NodeBannerTeaser({ node }) {
  const router = useRouter();
  const { t } = useTranslation("common");
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);

  const redirectToAnchor = (anchor) => {
    const element = document.getElementById(anchor);
    const path = router.asPath.split("#")[0];

    if (element) {
      router.push(`${path}#${anchor}`);
    }
  };

  return (
    <div
      ref={ref}
      className="relative flex-1 flex flex-col justify-center py-8"
    >
      <div
        className="absolute top-1/2 left-0 w-screen p-8 -translate-y-1/2"
        aria-hidden="true"
      >
        <Parallax
          translateY={[-15, 15]}
          className="bg-gradient-to-r from-white  via-transparent bg-clip-text text-transparent text-[210px] font-serif whitespace-nowrap overflow-hidden opacity-10"
        >
          {node.title}
        </Parallax>
      </div>
      <div className="relative container mx-auto px-4">
        <div className="xl:w-2/3 2xl:w-1/2">
          <Transition
            as="h2"
            show={isInViewport}
            enter="transition-all duration-500 delay-300"
            enterFrom="opacity-0 -translate-x-10"
            enterTo="opacity-100 translate-x-0"
            leave="transition-all duration-300"
            leaveFrom="opacity-100 translate-x-0"
            leaveTo="opacity-0 -translate-x-10"
            className="text-white font-serif text-5xl transition-all mb-4"
          >
            {node.title}
          </Transition>

          {node.body && (
            <Transition
              as="div"
              show={isInViewport}
              enter="transition-all duration-500 delay-500"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-10"
              className="prose prose-white max-w-none mx-0 mb-4 transition-all"
            >
              <Body value={node.body.processed} />
            </Transition>
          )}

          {node.field_anchor && (
            <Transition
              as="div"
              show={isInViewport}
              enter="transition-all duration-500 delay-700"
              enterFrom="opacity-0 -translate-x-10"
              enterTo="opacity-100 translate-x-0"
              leave="transition-all duration-300"
              leaveFrom="opacity-100 translate-x-0"
              leaveTo="opacity-0 -translate-x-10"
              className="mt-10"
            >
              <Button onClick={() => redirectToAnchor(node.field_anchor)}>
                {t("utils.more_link")}
              </Button>
            </Transition>
          )}
        </div>
      </div>
    </div>
  );
}

export function NodeBannerTeaserImage({ node }) {
  const ref = useRef(null);
  const isInViewport = useIsInViewport(ref);
  const transitionDuration = 1;
  const transitionDelay = 0;

  return (
    <div
      ref={ref}
      className="transition-all"
      style={{
        opacity: isInViewport ? 100 : 0,
        transitionDuration: `${transitionDuration}s`,
        transitionDelay: `${transitionDelay}s`,
      }}
    >
      <Image
        src={node.field_image.image_style_uri.hq}
        {...node.field_image.resourceIdObjMeta}
        layout="fill"
        objectFit="cover"
        objectPosition="center"
      />
    </div>
  );
}
