import React from "react";
import Link from "next/link";
import classNames from "lib/classNames";

export default function MainMenu({ menu, closeCallback = () => null }) {
  return menu && <MainMenuItems menu={menu} closeCallback={closeCallback} />;
}

function MainMenuItems({ menu, closeCallback }) {
  return (
    <ul
      id="main-menu"
      className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10"
    >
      {menu.map((link) => {
        const hasDropdown = link.items?.length > 0;

        return (
          <li key={link.url} className="relative">
            <Link href={link.url} passHref>
              <a
                className={classNames(
                  "group flex py-5 border-secondary-light",
                  hasDropdown
                    ? "flex-col border-b"
                    : "flex-row items-center gap-2 border-t"
                )}
                onClick={closeCallback}
              >
                <span className="counter text-primary" aria-hidden="true" />
                <span className="text-xl font-medium group-hover:text-primary transition-colors duration-300">
                  {link.title}
                </span>
              </a>
            </Link>
            {hasDropdown && (
              <ul className="flex flex-col py-2">
                {link.items.map((subLink) => {
                  return (
                    <li key={subLink.url}>
                      <Link href={subLink.url} passHref>
                        <a
                          className="block font-medium py-2 hover:text-primary transition-colors duration-300"
                          onClick={closeCallback}
                        >
                          {subLink.title}
                        </a>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        );
      })}
    </ul>
  );
}
