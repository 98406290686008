import React from "react";
import Image from "next/image";
import useTranslation from "next-translate/useTranslation";

export default function BrandingContent({ home = false }) {
  const { t } = useTranslation("common");
  const TitleTag = home ? "h1" : "div";

  return (
    <div className="flex gap-8 py-4 xl:py-10 px-4 md:px-16">
      <div className="hidden md:block border-r pr-8">
        <Image
          src="/images/logo.png"
          width="151"
          height="74"
          layout="intrinsic"
          alt={t("branding.alt")}
        />
      </div>
      <TitleTag className="flex flex-col justify-center font-serif font-medium text-2xl">
        {t("branding.title.part1")}
        <br />
        {t("branding.title.part2")}
      </TitleTag>
    </div>
  );
}
