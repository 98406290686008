export default function getDelayByIndex(index = 0) {
  const delayByIndex = {
    0: "delay-[300ms]",
    1: "delay-[500ms]",
    2: "delay-[700ms]",
    3: "delay-[900ms]",
    4: "delay-[1100ms]",
    5: "delay-[1300ms]",
    6: "delay-[1500ms]",
    7: "delay-[1700ms]",
    8: "delay-[1900ms]",
    9: "delay-[2100ms]",
  };

  return index > 9 ? delayByIndex[0] : delayByIndex[index];
}
