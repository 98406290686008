import React, { createRef, useRef } from "react";
import PreviewAlert from "components/PreviewAlert";
import FooterMenu from "components/Menu/FooterMenu";
import NodeBannerList from "components/Banner/NodeBannerList";
import Branding from "components/Branding";
import useTranslation from "next-translate/useTranslation";
import Navbar from "components/Navbar";
import { ArrowDownIcon } from "@heroicons/react/outline";
import classNames from "lib/classNames";
import { Transition } from "@headlessui/react";
import useIsInViewport from "hook/useIsInViewport";
import IsicsLogo from "./IsicsLogo";

export default function Layout({
  children,
  globals,
  banners,
  header = null,
  home = false,
}) {
  const { t } = useTranslation("common");
  const topRef = createRef();
  const navbarRef = useRef();
  const mainRef = createRef();
  const isMainInViewport = useIsInViewport(mainRef);
  const hasBanners = banners?.length > 0;
  const year = new Date().getFullYear();

  const scrollToContent = () => {
    if (mainRef.current) {
      mainRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <PreviewAlert />

      <header ref={topRef} className="relative z-20 overflow-hidden">
        <div
          className="absolute top-0 right-0 w-[36vw] h-[36vw] rounded-full bg-primary blur-3xl opacity-30 translate-x-1/3 -translate-y-1/3 z-20"
          aria-hidden="true"
        />

        <div ref={navbarRef} className="relative z-30">
          <div className="absolute top-0 left-0">
            <Branding home={home} />
          </div>
          <div className="fixed top-0 right-0">
            <Navbar globals={globals} />
          </div>
        </div>

        <div
          className={classNames(
            "relative flex flex-col",
            hasBanners && "lg:h-screen"
          )}
        >
          <div className="flex-1">
            {hasBanners > 0 ? <NodeBannerList nodes={banners} /> : header}
          </div>
          <div className="container mx-auto px-4 py-5">
            <button
              className="group flex items-center gap-4"
              onClick={() => scrollToContent()}
              type="button"
            >
              <span className="p-4 border border-secondary-light rounded-full bg-transparent group-hover:bg-secondary-light transition-colors duration-300">
                <ArrowDownIcon className="h-4 w-4 text-primary" />
              </span>
              <span className="group-hover:text-primary transition-colors duration-300">
                Découvrir
              </span>
            </button>
          </div>
        </div>
      </header>

      <main ref={mainRef} className="relative">
        {home ? (
          children
        ) : (
          <Transition
            show={isMainInViewport}
            enter="transition-opacity duration-500 delay-700"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            {children}
          </Transition>
        )}
      </main>

      <footer className="relative">
        <div className="relative">
          <div className="container mx-auto px-4">
            <div className="flex flex-col lg:flex-row justify-between items-center gap-8 border-t border-secondary-light py-8">
              <div>
                <span>Copyright Chooz {year}</span>
              </div>
              <div>
                <span className="font-serif font-medium text-secondary-light text-xl">
                  Mémoire vive
                </span>
              </div>
              <div>
                <a
                  href="https://www.isics.fr"
                  target="blank"
                  className="text-sm text-white hover:text-primary transition-colors flex gap-2"
                >
                  {t("copyright.text")} <IsicsLogo className="w-20" />
                </a>
              </div>
            </div>
            {globals?.menus?.footer && (
              <div className="pb-8">
                <FooterMenu menu={globals.menus.footer} />
              </div>
            )}
          </div>
        </div>
      </footer>
    </>
  );
}
